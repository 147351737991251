import React from 'react';

const GoogleLogo = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg
    id='google-logo'
    viewBox='1635 200 1185 600'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    {...props}
    fill='var(--quaternary-color)'
  >
    <g>
      <path d=' M 2143.153 531.761 C 2143.153 587.997 2099.159 629.437 2045.169 629.437 C 1991.178 629.437 1947.184 587.997 1947.184 531.761 C 1947.184 475.128 1991.178 434.085 2045.169 434.085 C 2099.159 434.085 2143.153 475.128 2143.153 531.761 Z  M 2100.26 531.761 C 2100.26 496.619 2074.762 472.574 2045.169 472.574 C 2015.575 472.574 1990.077 496.619 1990.077 531.761 C 1990.077 566.551 2015.575 590.948 2045.169 590.948 C 2074.762 590.948 2100.26 566.507 2100.26 531.761 Z ' />
      <path d=' M 2354.534 531.761 C 2354.534 587.997 2310.541 629.437 2256.55 629.437 C 2202.56 629.437 2158.566 587.997 2158.566 531.761 C 2158.566 475.172 2202.56 434.085 2256.55 434.085 C 2310.541 434.085 2354.534 475.128 2354.534 531.761 Z  M 2311.642 531.761 C 2311.642 496.619 2286.144 472.574 2256.55 472.574 C 2226.957 472.574 2201.459 496.619 2201.459 531.761 C 2201.459 566.551 2226.957 590.948 2256.55 590.948 C 2286.144 590.948 2311.642 566.507 2311.642 531.761 Z ' />
      <path d=' M 2557.109 439.986 L 2557.109 615.345 C 2557.109 687.479 2514.568 716.94 2464.277 716.94 C 2416.936 716.94 2388.444 685.277 2377.698 659.383 L 2415.043 643.837 C 2421.692 659.735 2437.986 678.495 2464.233 678.495 C 2496.424 678.495 2516.374 658.634 2516.374 621.246 L 2516.374 607.198 L 2514.876 607.198 C 2505.276 619.044 2486.78 629.393 2463.44 629.393 C 2414.602 629.393 2369.86 586.852 2369.86 532.113 C 2369.86 476.978 2414.602 434.085 2463.44 434.085 C 2486.736 434.085 2505.232 444.434 2514.876 455.928 L 2516.374 455.928 L 2516.374 440.03 L 2557.109 440.03 L 2557.109 439.986 Z  M 2519.412 532.113 C 2519.412 497.72 2496.468 472.574 2467.271 472.574 C 2437.678 472.574 2412.885 497.72 2412.885 532.113 C 2412.885 566.154 2437.678 590.948 2467.271 590.948 C 2496.468 590.948 2519.412 566.154 2519.412 532.113 Z ' />
      <path d=' M 2624.266 337.202 L 2624.266 623.448 L 2582.43 623.448 L 2582.43 337.202 L 2624.266 337.202 Z ' />
      <path d=' M 2787.294 563.908 L 2820.587 586.104 C 2809.842 602.001 2783.948 629.393 2739.205 629.393 C 2683.717 629.393 2642.278 586.5 2642.278 531.717 C 2642.278 473.631 2684.07 434.041 2734.405 434.041 C 2785.093 434.041 2809.886 474.379 2817.989 496.178 L 2822.437 507.276 L 2691.864 561.354 C 2701.861 580.951 2717.406 590.948 2739.205 590.948 C 2761.048 590.948 2776.197 580.202 2787.294 563.908 Z  M 2684.818 528.766 L 2772.101 492.523 C 2767.301 480.325 2752.857 471.825 2735.858 471.825 C 2714.06 471.825 2683.717 491.07 2684.818 528.766 L 2684.818 528.766 Z ' />
      <path d=' M 1788.824 506.351 L 1788.824 464.911 L 1928.468 464.911 C 1929.833 472.134 1930.538 480.677 1930.538 489.925 C 1930.538 521.016 1922.039 559.461 1894.647 586.852 C 1868.004 614.596 1833.963 629.393 1788.868 629.393 C 1705.284 629.393 1635 561.31 1635 477.726 C 1635 394.143 1705.284 326.06 1788.868 326.06 C 1835.108 326.06 1868.048 344.204 1892.798 367.852 L 1863.556 397.093 C 1845.809 380.447 1821.765 367.5 1788.824 367.5 C 1727.788 367.5 1680.051 416.69 1680.051 477.726 C 1680.051 538.763 1727.788 587.953 1788.824 587.953 C 1828.414 587.953 1850.962 572.055 1865.406 557.611 C 1877.12 545.897 1884.827 529.163 1887.865 506.307 L 1788.824 506.351 Z ' />
    </g>
  </svg>
);

GoogleLogo.displayName = 'GoogleLogo';

export default GoogleLogo;
