import React from 'react';

const SpotifyLogo = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg
    id='amazon-logo'
    viewBox='93.907 250 1350 472'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    {...props}
    fill='var(--quaternary-color)'
  >
    <g>
      <path
        d=' M 627.609 633.492 C 577.617 670.34 505.156 690 442.768 690 C 355.291 690 276.539 657.645 216.96 603.833 C 212.279 599.602 216.473 593.835 222.09 597.13 C 286.387 634.54 365.888 657.046 448.011 657.046 C 503.396 657.046 564.323 645.587 620.344 621.808 C 628.808 618.213 635.885 627.35 627.609 633.492'
        fillRule='evenodd'
      />
      <path
        d=' M 648.393 609.712 C 642.027 601.549 606.152 605.855 590.049 607.765 C 585.144 608.364 584.395 604.095 588.814 601.025 C 617.386 580.915 664.27 586.72 669.738 593.46 C 675.205 600.238 668.315 647.235 641.465 669.666 C 637.346 673.111 633.414 671.276 635.249 666.708 C 641.278 651.654 654.796 617.914 648.393 609.712'
        fillRule='evenodd'
      />
      <path
        d=' M 591.173 459.061 L 591.173 439.513 C 591.173 436.555 593.42 434.57 596.116 434.57 L 683.631 434.57 C 686.439 434.57 688.686 436.593 688.686 439.513 L 688.686 456.252 C 688.649 459.061 686.289 462.731 682.095 468.535 L 636.746 533.282 C 653.598 532.87 671.385 535.379 686.664 543.992 C 690.109 545.939 691.045 548.785 691.308 551.594 L 691.308 572.452 C 691.308 575.298 688.162 578.631 684.867 576.908 C 657.942 562.791 622.179 561.255 592.409 577.058 C 589.375 578.706 586.192 575.411 586.192 572.565 L 586.192 552.755 C 586.192 549.572 586.23 544.142 589.413 539.311 L 641.952 463.967 L 596.228 463.967 C 593.42 463.967 591.173 461.982 591.173 459.061'
        fillRule='evenodd'
      />
      <path
        d=' M 271.933 581.028 L 245.307 581.028 C 242.761 580.84 240.739 578.931 240.552 576.497 L 240.552 439.85 C 240.552 437.117 242.836 434.945 245.682 434.945 L 270.51 434.945 C 273.093 435.057 275.153 437.042 275.34 439.513 L 275.34 457.376 L 275.827 457.376 C 282.306 440.113 294.476 432.061 310.878 432.061 C 327.542 432.061 337.953 440.113 345.442 457.376 C 351.883 440.113 366.525 432.061 382.216 432.061 C 393.375 432.061 405.583 436.667 413.035 447.003 C 421.461 458.499 419.738 475.201 419.738 489.843 L 419.701 576.085 C 419.701 578.818 417.416 581.028 414.57 581.028 L 387.983 581.028 C 385.324 580.84 383.189 578.706 383.189 576.085 L 383.189 503.661 C 383.189 497.894 383.713 483.514 382.44 478.047 C 380.456 468.872 374.501 466.288 366.787 466.288 C 360.346 466.288 353.606 470.595 350.872 477.485 C 348.138 484.376 348.4 495.909 348.4 503.661 L 348.4 576.085 C 348.4 578.818 346.116 581.028 343.27 581.028 L 316.682 581.028 C 313.986 580.84 311.889 578.706 311.889 576.085 L 311.852 503.661 C 311.852 488.42 314.361 465.989 295.45 465.989 C 276.314 465.989 277.063 487.858 277.063 503.661 L 277.063 576.085 C 277.063 578.818 274.779 581.028 271.933 581.028'
        fillRule='evenodd'
      />
      <path
        d=' M 764.031 432.061 C 803.538 432.061 824.92 465.989 824.92 509.128 C 824.92 550.808 801.291 583.874 764.031 583.874 C 725.235 583.874 704.115 549.946 704.115 507.668 C 704.115 465.128 725.497 432.061 764.031 432.061 Z  M 764.255 459.96 C 744.633 459.96 743.397 486.697 743.397 503.362 C 743.397 520.063 743.135 555.713 764.031 555.713 C 784.664 555.713 785.638 526.953 785.638 509.428 C 785.638 497.894 785.151 484.113 781.668 473.179 C 778.673 463.667 772.718 459.96 764.255 459.96 Z '
        fillRule='evenodd'
      />
      <path
        d=' M 876.149 581.028 L 849.636 581.028 C 846.977 580.84 844.842 578.706 844.842 576.085 L 844.805 439.401 C 845.03 436.892 847.239 434.945 849.935 434.945 L 874.613 434.945 C 876.935 435.057 878.845 436.63 879.369 438.765 L 879.369 459.66 L 879.856 459.66 C 887.308 440.974 897.756 432.061 916.142 432.061 C 928.088 432.061 939.734 436.368 947.224 448.164 C 954.189 459.099 954.189 477.485 954.189 490.704 L 954.189 576.721 C 953.89 579.118 951.68 581.028 949.059 581.028 L 922.359 581.028 C 919.925 580.84 917.902 579.043 917.64 576.721 L 917.64 502.5 C 917.64 487.559 919.363 465.689 900.976 465.689 C 894.498 465.689 888.544 470.033 885.585 476.624 C 881.841 484.975 881.354 493.288 881.354 502.5 L 881.354 576.085 C 881.316 578.818 878.995 581.028 876.149 581.028'
        fillRule='evenodd'
      />
      <path
        d=' M 970.853 570.168 C 970.853 563.278 976.733 557.735 983.96 557.735 C 991.187 557.735 997.067 563.278 997.067 570.168 C 997.067 577.021 991.187 582.638 983.96 582.638 C 976.733 582.638 970.853 577.021 970.853 570.168 Z '
        fillRule='evenodd'
      />
      <path
        d=' M 1252.946 581.065 C 1250.174 580.953 1248.003 578.818 1248.003 576.122 L 1248.003 439.476 C 1248.152 437.042 1250.212 435.132 1252.758 434.982 L 1262.532 434.982 C 1265.228 434.982 1267.4 436.93 1267.625 439.476 L 1267.625 459.398 C 1274.59 443.595 1287.547 431.238 1303.912 431.238 L 1304.923 431.238 L 1305.896 431.238 C 1323.272 431.238 1335.967 444.045 1340.685 462.656 C 1348.062 444.269 1361.918 431.238 1380.192 431.238 L 1381.128 431.238 L 1382.214 431.238 C 1395.134 431.238 1407.566 439.551 1414.007 452.208 C 1420.224 464.304 1419.999 480.406 1419.999 493.925 L 1419.962 576.122 C 1419.999 578.743 1417.865 580.878 1415.206 581.065 L 1403.522 581.065 C 1400.976 580.953 1398.916 579.155 1398.579 576.796 L 1398.579 493.925 C 1398.579 484.151 1399.066 473.778 1395.096 464.865 C 1391.052 455.766 1383.301 450.074 1374.987 449.662 C 1365.7 450.111 1357.162 456.889 1351.62 466.026 C 1344.43 477.822 1344.692 488.457 1344.692 502.238 L 1344.692 576.871 C 1344.355 579.118 1342.37 580.878 1339.936 581.065 L 1328.328 581.065 C 1325.556 580.953 1323.347 578.818 1323.347 576.122 L 1323.272 488.457 C 1323.272 480.406 1322.785 470.932 1319.078 463.705 C 1314.772 455.466 1307.057 450.074 1298.969 449.662 C 1290.58 450.149 1282.117 456.552 1277.099 464.004 C 1270.621 473.516 1269.385 485.274 1269.385 497.37 L 1269.385 576.122 C 1269.385 578.743 1267.251 580.878 1264.592 581.065 L 1252.946 581.065'
        fillRule='evenodd'
      />
      <path
        d=' M 1172.733 583.911 C 1134.948 583.911 1118.022 545.378 1118.022 506.844 C 1118.022 466.326 1137.907 431.238 1175.953 431.238 L 1176.927 431.238 L 1177.976 431.238 C 1214.974 431.238 1233.398 468.61 1233.398 507.144 C 1233.398 547.961 1212.989 583.911 1174.718 583.911 L 1173.744 583.911 L 1172.733 583.911 M 1175.504 565.225 C 1187.937 564.813 1197.748 557.099 1203.552 544.254 C 1208.757 532.72 1209.769 519.501 1209.769 506.844 C 1209.769 493.063 1208.271 478.684 1201.792 466.888 C 1195.988 456.59 1186.027 450.036 1175.467 449.662 C 1163.708 450.074 1153.185 458.05 1147.905 470.333 C 1143.149 480.968 1141.689 495.086 1141.689 506.844 C 1141.689 520.063 1143.412 535.342 1148.879 546.838 C 1154.196 557.735 1164.42 564.813 1175.504 565.225'
        fillRule='evenodd'
      />
      <path
        d=' M 1054.661 564.626 C 1071.625 564.101 1080.537 550.508 1084.207 532.908 C 1084.956 530.698 1086.641 529.013 1089.113 529.013 L 1100.309 528.976 C 1102.968 529.088 1105.402 531.11 1105.178 533.582 C 1100.01 563.577 1081.923 583.911 1056.159 583.911 L 1055.11 583.911 L 1054.137 583.911 C 1016.614 583.911 1000.437 546.239 1000.437 508.005 C 1000.437 470.071 1016.839 431.238 1054.361 431.238 L 1055.372 431.238 L 1056.383 431.238 C 1082.447 431.238 1101.021 451.309 1105.065 481.305 C 1105.065 483.552 1102.968 485.499 1100.497 485.761 L 1088.776 485.611 C 1086.304 485.274 1084.694 483.177 1084.319 480.818 C 1081.511 464.079 1071.887 450.748 1055.859 450.223 C 1030.357 451.047 1023.093 482.428 1023.093 506.582 C 1023.093 529.837 1029.159 563.802 1054.661 564.626'
        fillRule='evenodd'
      />
      <path
        d=' M 521.408 515.757 C 521.408 526.13 521.67 534.78 516.427 543.992 C 512.196 551.482 505.455 556.088 498.003 556.088 C 487.78 556.088 481.789 548.299 481.789 536.802 C 481.789 514.109 502.123 509.99 521.408 509.99 L 521.408 515.757 Z  M 548.258 580.653 C 546.498 582.226 543.951 582.338 541.967 581.29 C 533.129 573.95 531.519 570.542 526.688 563.54 C 512.084 578.444 501.711 582.9 482.8 582.9 C 460.369 582.9 442.955 569.082 442.955 541.408 C 442.955 519.801 454.639 505.084 471.341 497.894 C 485.795 491.528 505.98 490.405 521.408 488.645 L 521.408 485.199 C 521.408 478.871 521.895 471.381 518.15 465.914 C 514.93 461.008 508.713 458.986 503.209 458.986 C 493.06 458.986 484.035 464.191 481.826 474.976 C 481.377 477.373 479.617 479.732 477.182 479.844 L 451.381 477.073 C 449.209 476.587 446.775 474.826 447.412 471.494 C 453.328 440.188 481.639 430.751 506.953 430.751 C 519.91 430.751 536.836 434.196 547.06 444.007 C 560.016 456.103 558.781 472.243 558.781 489.805 L 558.781 531.297 C 558.781 543.767 563.948 549.235 568.817 555.975 C 570.502 558.372 570.876 561.255 568.704 563.053 C 563.274 567.584 553.613 576.01 548.295 580.728 L 548.258 580.653'
        fillRule='evenodd'
      />
      <path
        d=' M 172.36 515.757 C 172.36 526.13 172.622 534.78 167.379 543.992 C 163.147 551.482 156.444 556.088 148.955 556.088 C 138.732 556.088 132.778 548.299 132.778 536.802 C 132.778 514.109 153.112 509.99 172.36 509.99 L 172.36 515.757 Z  M 199.209 580.653 C 197.449 582.226 194.903 582.338 192.918 581.29 C 184.081 573.95 182.508 570.542 177.64 563.54 C 163.035 578.444 152.7 582.9 133.751 582.9 C 111.358 582.9 93.907 569.082 93.907 541.408 C 93.907 519.801 105.628 505.084 122.292 497.894 C 136.747 491.528 156.931 490.405 172.36 488.645 L 172.36 485.199 C 172.36 478.871 172.846 471.381 169.139 465.914 C 165.881 461.008 159.665 458.986 154.198 458.986 C 144.049 458.986 134.987 464.191 132.778 474.976 C 132.328 477.373 130.568 479.732 128.172 479.844 L 102.333 477.073 C 100.161 476.587 97.764 474.826 98.363 471.494 C 104.317 440.188 132.59 430.751 157.905 430.751 C 170.862 430.751 187.788 434.196 198.011 444.007 C 210.968 456.103 209.732 472.243 209.732 489.805 L 209.732 531.297 C 209.732 543.767 214.9 549.235 219.768 555.975 C 221.491 558.372 221.865 561.255 219.693 563.053 C 214.263 567.584 204.602 576.01 199.284 580.728 L 199.209 580.653'
        fillRule='evenodd'
      />
    </g>
  </svg>
);

SpotifyLogo.displayName = 'SpotifyLogo';

export default SpotifyLogo;
